import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Layout from "../../../../components/Layout";
import FormSB from "../../../../components/Form";
import BlogNews from "../../../../components/BlogNews";

export default class ContratosCessaoUsoSoftware extends Component {
    state = {
        data: [],
    }

    componentDidMount () {
        document.title = "Site Blindado - ssssContratos Padrão de Cessão de Uso de Software Via Internet";
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render () {
        return (
            <Layout>
                <div className="padding-container padding-top-mobile bg-dark-green">
                    <Container>
                        <Row className="h-100 align-items-center">
                            <Col xs={12} md={8}>
                                <h1 className="font-black mb-4">Contrato Padrão de Cessão de Uso de Software Via Internet</h1>
                            </Col>
                            <Col xs={12} md={4}>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <Col xs={12} md className="mb-md-0 mb-4" style={{ textAlign: 'center' }}>
                                <h4 style={{ color: 'black' }}>Conheça as condições gerais de uso dos serviços oferecidos pela Site Blindado</h4>
                                <h4 style={{ color: 'black', marginBottom: '5rem' }}>Última atualização e versões anteriores:</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/CONTRATOPADRAODECESSAODEUSODESOFTWAREVIAINTERNET_20250219.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faFilePdf} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h5 className="font-light text-center color-dark">Versão 3</h5>
                                                <h6 className="font-light text-center color-dark">Ultima versão</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/CONTRATOPADRAODECESSAODEUSODESOFTWAREVIAINTERNET_20241119.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faFilePdf} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h5 className="font-light text-center color-dark">Versão 2</h5>
                                                <h6 className="font-light text-center color-dark">Vigente até 28/02/2025</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/CONTRATOPADRAODECESSAODEUSODESOFTWAREVIAINTERNET.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faFilePdf} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h5 className="font-light text-center color-dark">Versão 1</h5>
                                                <h6 className="font-light text-center color-dark">Vigente até 19/11/2024</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                        </Row>
                        <Row className="padding-row" style={{ marginTop: '5rem' }}>
                            <Col xs={12} md={12}>
                                <FormSB color="white" type="form-client" source="Página de Contratos e Serviços" parentCallback={this.callbackFunction} title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout >
        );
    }
}
